'use client';

import { useRef, useState } from 'react';
import type { JSX } from 'react';

// <FAQ> component is a lsit of <Item> component
// Just import the FAQ & add your FAQ content to the const faqList arrayy below.

interface FAQItemProps {
  question: string;
  answer: JSX.Element;
}

const faqList: FAQItemProps[] = [
  {
    question: 'How do students join?',
    answer: (
      <div className="space-y-2 leading-relaxed">
        Students can join a quiz at{' '}
        <a
          href="https://quizwhizzer.com/play"
          className="link-primary"
          target="_blank"
        >
          quizwhizzer.com/play
        </a>
        . Simply enter the unique code provided by the game host and then enter
        a nickname. No account required!
      </div>
    ),
  },
  {
    question: 'How many players in a game?',
    answer: (
      <div>
        <p>
          Up to 30 players can join a game on the free plan. If you need to
          support more than this, the Pro plan allows up to 100 players.
        </p>
      </div>
    ),
  },
  {
    question: 'How easy is it to set up a quiz on QuizWhizzer?',
    answer: (
      <div>
        <p>
          QuizWhizzer is designed to be user-friendly and intuitive. Most
          teachers can set up a quiz in just a few minutes with our
          straightforward interface. Plus, you can save questions from
          ready-made quizzes to your personal question bank, making future quiz
          creation even faster!
        </p>
      </div>
    ),
  },
  {
    question: 'Can I use QuizWhizzer for any subject?',
    answer: (
      <div>
        <p>
          Absolutely! QuizWhizzer is adaptable for any subject and grade level.
          Whether you’re teaching math, science, history, or language arts, you
          can customize quizzes to fit your curriculum needs.
        </p>
      </div>
    ),
  },
  {
    question: "Is my students' data safe on QuizWhizzer?",
    answer: (
      <div>
        <p>
          Absolutely! Data privacy and security are top priorities for us. With
          QuizWhizzer, students don&apos;t need to sign up or share personal
          information to join games—just a username. This ensures a safe and
          anonymous experience, keeping student data secure while they enjoy
          interactive learning.
        </p>
      </div>
    ),
  },
  // {
  //   question: 'Is there a mobile app?',
  //   answer: (
  //     <div>
  //       <p>
  //         Not yet, but your students should have no problems joining a game in
  //         their smartphone web browser as the site is optimized for mobile.
  //       </p>
  //     </div>
  //   ),
  // },
  {
    question: 'I have another question',
    answer: (
      <div className="space-y-2 leading-relaxed">
        Get in touch at{' '}
        <a href="mailto:support@quizwhizzer.com" className="link-primary">
          support@quizwhizzer.com
        </a>
        . Typical response time 1 working day.
      </div>
    ),
  },
];

const FaqItem = ({ item }: { item: FAQItemProps }) => {
  const accordion = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li>
      <button
        className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        aria-expanded={isOpen}
      >
        <span
          className={`flex-1 text-base-content ${isOpen ? 'text-primary' : ''}`}
        >
          {item?.question}
        </span>
        <svg
          className={`flex-shrink-0 w-4 h-4 ml-auto fill-current`}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              isOpen && 'rotate-180'
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              isOpen && 'rotate-180 hidden'
            }`}
          />
        </svg>
      </button>

      <div
        ref={accordion}
        className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`}
        style={
          isOpen
            ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0 }
        }
      >
        <div className="pb-5 leading-relaxed">{item?.answer}</div>
      </div>
    </li>
  );
};

const FAQ = () => {
  return (
    <section className="bg-base-100" id="faq">
      <div className="py-24 px-8 max-w-7xl mx-auto flex flex-col md:flex-row gap-12">
        <div className="flex flex-col text-left basis-1/2">
          <p className="inline-block font-semibold text-primary mb-4">FAQ</p>
          <p className="sm:text-4xl text-3xl font-extrabold text-base-content">
            Frequently Asked Questions
          </p>
        </div>

        <ul className="basis-1/2">
          {faqList.map((item, i) => (
            <FaqItem key={i} item={item} />
          ))}
        </ul>
      </div>
    </section>
  );
};

export default FAQ;
