'use client';

import * as React from 'react';
import mixpanel from 'mixpanel-browser';

export default function EventViewHomepage() {
  React.useEffect(() => {
    mixpanel.track('homepage_viewed');
  }, []);

  return null;
}
