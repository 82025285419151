'use client';

import React from 'react';
import { Check } from 'lucide-react';
import config from '@/config';

const plans: Array<{
  name: string;
  period: string;
  isFeatured?: boolean;
  description?: string;
  priceAnchor?: number;
  price: number;
  features: string[];
}> = [
  {
    price: 0,
    period: 'month',
    name: 'Free',
    features: [
      '1 quiz',
      'Unlimited plays',
      'Unlimited custom maps',
      'Question Bank',
      'Custom avatars',
      '3 saved reports',
      'Up to 30 players',
    ],
  },
  {
    name: 'Monthly',
    period: 'month',
    price: 4,
    features: [
      'All Starter features',
      'Unlimited quizzes',
      'Private quizzes',
      'Practice mode',
      '20 saved reports',
      'Excel reports',
      'Up to 100 players',
    ],
  },
  {
    name: 'Yearly',
    period: 'year',
    price: 39,
    isFeatured: true,
    features: ['Everything in Monthly', 'Pay yearly, save 20%'],
  },
];

export default function Pricing() {
  return (
    <section className="bg-base-200/60" id="pricing">
      <div className="py-24 px-8 max-w-5xl mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <p className="font-medium text-primary mb-8">Pricing</p>
          <div className="flex flex-col gap-8">
            <div>
              <h2 className="font-bold text-3xl lg:text-5xl tracking-tight mb-8">
                Your lessons taken to the next level
              </h2>
            </div>
            <div className="px-8">
              <div className="relative flex justify-center flex-col lg:flex-row items-center lg:items-stretch gap-8">
                {plans.map((plan) => {
                  let displayPrice: string | number = plan.price;

                  if (plan.period === 'year') {
                    displayPrice = (plan.price / 12).toFixed(2);
                  }

                  return (
                    <div key={plan.name} className="relative w-full max-w-lg">
                      {plan.isFeatured && (
                        <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20">
                          <span
                            className={`badge text-xs text-primary-content font-semibold border-0 bg-primary`}
                          >
                            POPULAR
                          </span>
                        </div>
                      )}

                      {plan.isFeatured && (
                        <div
                          className={`absolute -inset-[1px] rounded-[9px] bg-primary z-10`}
                        ></div>
                      )}

                      <div className="relative flex flex-col h-full gap-5 lg:gap-8 z-10 bg-base-100 p-8 rounded-lg">
                        <div className="flex justify-between items-center gap-4">
                          <div>
                            <p className="text-lg lg:text-xl font-bold">
                              {plan.name}
                            </p>
                            {plan.description && (
                              <p className="text-base-content/80 mt-2">
                                {plan.description}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col items-start gap-1">
                          <div className="flex gap-2">
                            {plan.priceAnchor && (
                              <div className="flex flex-col justify-end mb-[4px] text-lg ">
                                <p className="relative">
                                  <span className="absolute bg-base-content h-[1.5px] inset-x-0 top-[53%]"></span>
                                  <span className="text-base-content/80">
                                    ${plan.priceAnchor}
                                  </span>
                                </p>
                              </div>
                            )}
                            <p
                              className={`text-5xl tracking-tight font-extrabold`}
                            >
                              ${displayPrice}
                            </p>
                            <div className="flex flex-col justify-end mb-[4px]">
                              <p className="text-xs text-base-content/60 uppercase font-semibold">
                                {plan.period === 'once'
                                  ? 'one-time'
                                  : 'per month'}
                              </p>
                            </div>
                          </div>
                          {displayPrice !== plan.price ? (
                            <span className="text-base-content/60">
                              billed ${plan.price} annually
                            </span>
                          ) : (
                            <span className="h-4" />
                          )}
                        </div>
                        {plan.features && (
                          <ul className="space-y-2.5 leading-relaxed text-base flex-1">
                            {plan.features.map((feature, i) => (
                              <li key={i} className="flex items-center gap-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-[18px] h-[18px] opacity-80 shrink-0"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                    clipRule="evenodd"
                                  />
                                </svg>

                                <span>{feature} </span>
                              </li>
                            ))}
                          </ul>
                        )}
                        <div className="space-y-2">
                          <a
                            href={`${config.appUrl}/register`}
                            className="btn btn-primary w-full"
                          >
                            Get Started
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Benefit({ label }: { label: string }) {
  return (
    <div className="flex items-center gap-2">
      <Check className="w-4 h-4 text-primary" />
      <p className="text-left">{label}</p>
    </div>
  );
}
