'use client';

import React from 'react';
import NextTopLoader from 'nextjs-toploader';
import { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import config from '@/config';
import mixpanel from 'mixpanel-browser';

const mixpanelProdToken = 'c6ea98c258d14a4fa37c1e7dd833cc19';
const mixpanelDevToken = '5ffaeb299e94e1448eefc94c948df6ef';

let token = mixpanelDevToken;
let debug = true;

if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
  token = mixpanelProdToken;
  debug = false;
}

if (typeof window !== 'undefined') {
  mixpanel.init(token, {
    api_host: 'https://api-eu.mixpanel.com',
    debug: debug || process.env.NEXT_PUBLIC_MIXPANEL_DEBUG === 'true',
    ignore_dnt: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
    track_pageview: 'url-with-path',
  });
}

// All the client wrappers are here (they can't be in server components)
// 2. NextTopLoader: Show a progress bar at the top when navigating between pages
// 3. Toaster: Show Success/Error messages anywhere from the app with toast()
// 4. Tooltip: Show tooltips if any JSX elements has these 2 attributes: data-tooltip-id="tooltip" data-tooltip-content=""
const ClientLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {/* Show a progress bar at the top when navigating between pages */}
      <NextTopLoader color={config.colors.main} showSpinner={false} />

      {/* <MixpanelLoader /> */}

      {/* Content inside app/page.js files  */}
      {children}

      {/* Show Success/Error messages anywhere from the app with toast() */}
      <Toaster
        toastOptions={{
          duration: 3000,
        }}
      />

      {/* Show tooltips if any JSX elements has these 2 attributes: data-tooltip-id="tooltip" data-tooltip-content="" */}
      <Tooltip
        id="tooltip"
        className="z-[60] !opacity-100 max-w-sm shadow-lg"
      />
    </>
  );
};

export default ClientLayout;
