'use client';

import React, { useState, useRef } from 'react';
import Image from 'next/image';
import { HelpCircle, Rocket, Users, Zap } from 'lucide-react';
import config from '@/config';
import { cn } from '@/src/libs/utils';

type Feature = {
  title: string;
  description: string;
  type?: 'video' | 'image';
  path?: string;
  format?: string;
  alt?: string;
  svg?: React.ReactNode;
  customContent?: React.ReactNode;
  className?: string;
};

// The features array is a list of features that will be displayed in the accordion.
// - title: The title of the feature
// - description: The description of the feature (when clicked)
// - type: The type of media (video or image)
// - path: The path to the media (for better SEO, try to use a local path)
// - format: The format of the media (if type is 'video')
// - alt: The alt text of the image (if type is 'image')
const features = [
  {
    title: 'Question types',
    description: `We have 10 questions types to support a variety of quizzing formats:
          multiple choice, multiple response, true/false, numerical answer,
          short text answer, drag and drop into text, ordering, open-ended,
          random question and audio answer.`,
    svg: <HelpCircle size={24} />,
    type: 'image',
    path: `${config.imagesBaseUrl}/landing/Question types2.png`,
    alt: 'Question types',
    // format: 'video/mp4',
    // type: 'video',
    // path: `${config.imagesBaseUrl}/landing/Question types demo3.mp4`,
  },
  {
    title: 'Power-ups',
    description:
      'Make the race even more interesting with unique powerups, visually watch the effects on the game screen!',
    alt: 'Powerup question mark',
    svg: <Zap size={24} />,
    customContent: (
      <div className="relative group w-full aspect-square sm:w-[26rem]">
        {/* Awesome glow effect here: https://tailwindflex.com/@prashant/glowing-gradient-border */}
        <div className="absolute -inset-1 bg-gradient-to-r from-red-600 to-violet-600 rounded-lg blur transition duration-1000 group-hover:duration-200 animate-pulse" />
        <div
          className={`bg-white relative rounded-lg flex justify-center items-center z-10 h-full bg-cover bg-[url('../../public/powerups-bg.png')] bg-center`}
        >
          <Image
            src={`${config.imagesBaseUrl}/powerups/Q-mark-512px.gif`}
            alt="Powerup question mark"
            width={80}
            height={80}
          />
          <Image
            src={`${config.imagesBaseUrl}/powerups/full cannon.png`}
            alt="Cannon powerup"
            width={120}
            height={120}
            className="scale-x-[-1] absolute bottom-1 right-4"
          />
          <Image
            src={`${config.imagesBaseUrl}/powerups/swap.png`}
            alt="Swap powerup"
            width={90}
            height={90}
            className="absolute top-2 right-3 lg:top-[55px] lg:right-[48px]"
          />
          <Image
            src={`${config.imagesBaseUrl}/powerups/speed boost.png`}
            alt="Energy blast powerup"
            width={100}
            height={100}
            className="absolute bottom-[40px] left-2 lg:left-[20px] -rotate-12"
          />
          <Image
            src={`${config.imagesBaseUrl}/powerups/freeze.png`}
            alt="Freeze powerup"
            width={100}
            height={100}
            className="absolute top-1 lg:top-[30px] left-2 lg:left-[30px]"
          />
        </div>
      </div>
    ),
  },
  {
    title: '100 Player Capacity',
    description: `Get ready to host epic quiz battles with a capacity of up to 100
          players! QuizWhizzer is designed to accommodate large groups, making
          it perfect for classrooms, corporate training, or large-scale online
          events.`,
    svg: <Users size={24} />,
    type: 'image',
    alt: '100 players in a game',
    path: `${config.imagesBaseUrl}/landing/100 players.png`,
  },
  {
    title: 'Custom Avatars',
    description:
      'Add your own avatars to players to customize your game further and increase engagement.',
    svg: <Rocket size={24} />,
    type: 'image',
    path: `${config.imagesBaseUrl}/landing/custom avatars.png`,
  },
] as Feature[];

// An SEO-friendly accordion component including the title and a description (when clicked.)
const Item = ({
  feature,
  isOpen,
  setFeatureSelected,
}: {
  index: number;
  feature: Feature;
  isOpen: boolean;
  setFeatureSelected: () => void;
}) => {
  const accordion = useRef<HTMLDivElement>(null);
  const { title, description, svg } = feature;

  return (
    <li>
      <button
        className="relative flex gap-2 items-center w-full py-5 text-base font-medium text-left md:text-lg"
        onClick={(e) => {
          e.preventDefault();
          setFeatureSelected();
        }}
        aria-expanded={isOpen}
      >
        <span className={`duration-100 ${isOpen ? 'text-primary' : ''}`}>
          {svg}
        </span>
        <span
          className={`flex-1 text-base-content ${
            isOpen ? 'text-primary font-semibold' : ''
          }`}
        >
          <h3 className="inline">{title}</h3>
        </span>
      </button>

      <div
        ref={accordion}
        className={`transition-all duration-300 ease-in-out text-base-content-secondary overflow-hidden`}
        style={
          isOpen
            ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0 }
        }
      >
        <div className="pb-5 leading-relaxed">{description}</div>
      </div>
    </li>
  );
};

// A component to display the media (video or image) of the feature. If the type is not specified, it will display an empty div.
// Video are set to autoplay for best UX.
const Media = ({ feature }: { feature: Feature }) => {
  const { type, path, format, alt, className } = feature;
  const style = 'rounded-2xl aspect-square w-full sm:w-[26rem]';
  const size = {
    width: 500,
    height: 500,
  };

  if (feature.customContent) {
    return feature.customContent;
  }

  if (type === 'video') {
    return (
      <video
        className={cn(style, className)}
        autoPlay
        muted
        loop
        playsInline
        controls
        width={size.width}
        height={size.height}
      >
        <source src={path} type={format} />
      </video>
    );
  } else if (type === 'image' && path) {
    return (
      <Image
        src={path}
        alt={alt ?? ''}
        className={cn(style, 'object-cover object-center', className)}
        width={size.width}
        height={size.height}
      />
    );
  } else {
    return <div className={`${style} !border-none`}></div>;
  }
};

export default function FeaturesAccordion() {
  const [featureSelected, setFeatureSelected] = useState<number>(0);

  /*
    Headlines:
    All you need to make lessons fun, not boring
    Check knowledge, dump the textbook
  */

  return (
    <section className="bg-base-200" id="features">
      <div className="py-24 md:py-32 space-y-24 md:space-y-32 max-w-7xl mx-auto">
        <div className="px-8">
          <h2 className="font-extrabold text-4xl lg:text-6xl tracking-tight mb-12 md:mb-24 break-words">
            Check knowledge,
            <span className="bg-neutral text-neutral-content px-2 md:px-4 ml-1 md:ml-1.5 leading-relaxed break-normal">
              dump the textbook
            </span>
          </h2>
          <div className=" flex flex-col md:flex-row gap-12 md:gap-24">
            <div className="grid grid-cols-1 items-stretch gap-8 sm:gap-12 lg:grid-cols-2 lg:gap-20">
              <ul className="w-full">
                {features.map((feature, i) => (
                  <Item
                    key={feature.title}
                    index={i}
                    feature={feature}
                    isOpen={featureSelected === i}
                    setFeatureSelected={() => setFeatureSelected(i)}
                  />
                ))}
              </ul>

              <Media
                feature={features[featureSelected]}
                key={featureSelected}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
